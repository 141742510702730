<template>
  <video autoplay muted loop id="myVideo" style="opacity: 1;">
    <source src="./assets/back2.mp4" type="video/mp4">
  </video>
  <div class="app-container">
    <nav class="navbar navbar-expand-lg" dir="rtl">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <div class="navbar-nav d-flex justify-content-between align-items-start">
            <div class="navbar-nav d-flex justify-content-between align-items-start" style="width: fit-content;">
              <div class="nav-item">
                <router-link to="/" type="button" class="nav-link" aria-current="page">
                  <p>
                    <svg class="ms-2" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="20" height="20" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve"><g><path d="m498.195 222.695-.035-.035L289.305 13.813C280.402 4.905 268.566 0 255.977 0c-12.59 0-24.426 4.902-33.332 13.809L13.898 222.55c-.07.07-.14.144-.21.215-18.282 18.386-18.25 48.218.09 66.558 8.378 8.383 19.445 13.238 31.277 13.746.48.047.965.07 1.453.07h8.324v153.7C54.832 487.254 79.578 512 110 512h81.71c8.282 0 15-6.715 15-15V376.5c0-13.879 11.29-25.168 25.169-25.168h48.195c13.88 0 25.168 11.29 25.168 25.168V497c0 8.285 6.715 15 15 15h81.711c30.422 0 55.168-24.746 55.168-55.16v-153.7h7.719c12.586 0 24.422-4.902 33.332-13.808 18.36-18.371 18.367-48.254.023-66.637zm0 0" fill="#000000" data-original="#000000"></path></g></svg>
                    خانه</p>
                </router-link>
                
                <!-- <svg viewBox="0 0 24 24">
                  <use xlink:href="./assets/home.svg"></use>
                </svg> -->
              </div>
              <div class="nav-item">
                <router-link to="/history" type="button" class="nav-link">
                  <p>
                    <svg class="ms-2" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="20" height="20" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve"><g><path d="M248.58 404.87a140.8 140.8 0 0 1 217.18-118.24c.15-10.43.23-21.56.23-33.57 0-61.75-2.1-100.29-6.25-140.21h.59A119.41 119.41 0 0 0 382.68 13c-1.11-.42-2.22-.82-3.34-1.2a119.37 119.37 0 0 0-25.58-5.57l-.83-.09C319.72 2.73 314.39 0 244.36 0S163 2.73 129.82 6.19l-.83.09a119.16 119.16 0 0 0-26.81 6v-.45A119.48 119.48 0 0 0 21.8 112.61c-4.16 40-6.28 78.58-6.28 140.45s2.12 100.45 6.28 140.45a119.59 119.59 0 0 0 106.58 106.57l.83.09c33.21 3.46 38.53 6.19 108.56 6.19 23.2 0 40-.3 53.19-.83a140.36 140.36 0 0 1-42.38-100.66zm-117-271.72h218.31a23.82 23.82 0 0 1 0 47.64H131.63a23.82 23.82 0 0 1 0-47.64zm0 149.57a23.83 23.83 0 0 1 0-47.65h124.51a23.83 23.83 0 1 1 0 47.65z" fill="#000000" data-original="#000000"></path><path d="M389.35 297.74a107.13 107.13 0 1 0 107.13 107.13 107.13 107.13 0 0 0-107.13-107.13zm42.46 122.81h-44.59a12.75 12.75 0 0 1-12.75-12.75v-68.5a12.75 12.75 0 1 1 25.5 0v55.75h31.84a12.75 12.75 0 0 1 0 25.5z" fill="#000000" data-original="#000000"></path></g></svg>
                    تاریخچه</p>
                </router-link>
              </div>
            </div>
            <div class="nav-item">
            </div>
          </div>
        </div>
      </div>
    </nav>
    <router-view></router-view>
    <!-- <Main msg="Welcome to Your Vue.js App" /> -->
  </div>
</template>

<script>
// import Main from './view/Main.vue'
// import Main from './view/History.vue'

export default {
  name: 'App',
  components: {
    // Main
  },
  methods: {
    
  }
}
</script>

<style></style>
