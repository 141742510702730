<template>
    <div class="wrapper">
        <div class="player1 circle x" @click="play">
            <div id="buffer" class="buffering circle animated"></div>
            <div class="triangle"></div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            playing: false
        }
    },
    methods: {
        play() {
            var au = document.querySelector('#recievedAudio')
            au.addEventListener('ended', this.stop.bind(this));
            var buf = document.querySelector('#buffer')
            if(this.playing) {
                au.pause()
                this.playing = false
                buf.classList.remove('active')
            }
            else {
                au.play()
                au.currentTime = 0;
                this.playing = true
                buf.classList.add('active')

            }
        },
        stop() {
            this.playing = false;
            var buf = document.querySelector('#buffer')
            buf.classList.remove('active')
        },
    }
}
</script>
<style scoped>
    
/* rotation */
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@-moz-keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@-ms-keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
.wrapper {
    position: relative;
    height: 120px;
    width: 80px;
    transform: translate(55px, -24px);
}

.circle {
    /* Draw circles */
    border-radius: 50%;
}

.x {
    display: block;
    background-color: #f8ede1;
    background-image: -webkit-gradient(linear,
            left top,
            left bottom,
            from(#f7f7f7),
            to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7);
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7);
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7);
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7);
    color: #a7a7a7;
    margin: 7.2px;
    width: 25px;
    height: 25px;
    position: relative;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
    box-shadow: 0px 3px 10px #aaa, inset 0px 2px 4px #fff;
}

.x:hover {
    background: #f5f5f5;
}

/* player */
.player1 {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -24px 0 0 -24px;
    height: 38px;
    width: 38px;
    /* background-color: #f2f0f0; */
    cursor: pointer;
}

.player1 .triangle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    margin: -8.8px 0 0 -4px;
    border-top: 9.6px solid transparent;
    border-left: 12px solid #47230F;
    border-bottom: 9.6px solid transparent;
}

.player1:hover .triangle {
    border-left: 12px solid #46ccbf;
}

.player1 .buffering {
    position: absolute;
    top: -5px;
    left: -5px;
    width: 48px;
    height: 48px;
    border-right: 4px solid #E9DCAD;
    border-bottom: 4px solid #47230F;
    border-left: 4px solid #E9DCAD;
    border-top: 4px solid #47230F;
    /* animation: rotate 5s infinite linear; */
}
.player1 .active {
    animation: rotate 5s infinite linear;
}
</style>