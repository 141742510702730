import { createStore } from 'vuex'

export default createStore({
  state: {
    history: []
  },
  mutations: {
    
  }
})
