<template>
    <div class="spinner">
        <div class="spinner-icon"></div>
    </div>
</template>
  
<style>
.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.spinner-icon {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #f8ae98;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>