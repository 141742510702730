<template>
    <div class="back">
        <div class="accordion accordion-flush" id="accordionFlushExample"
            style="height: 96%;overflow: scroll;padding: 5px;">
            <div class="accordion-item" dir="rtl" v-for="item in this.$store.state.history" v-bind:key="item[0]"
                style="border-radius: 12px;">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" style="padding: 10px; border-radius: 12px;" type="button"
                        data-bs-toggle="collapse" v-bind:data-bs-target="`#${item[0]}`" aria-expanded="false"
                        :aria-controls="item[0]">
                        <span class="ms-3" v-bind:style="{ color: item[1] ? 'green' : 'red' }" style="font-size: 32px;">{{
                            item[0] }}</span>
                    </button>
                </h2>
                <div :id="item[0]" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <div id="audio" class="" controls>
                                <audio src="" controls></audio>
                            </div>
                            <button
                                @click="!recording ? recordAudio($event.target.parentNode.parentNode.parentNode.parentNode) : stop()"
                                type="button" class="recordBtn" style="width: 50px; height: 50px; transform: inherit;"
                                v-bind:style="{ opacity: recording ? 0.7 : 1 }">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs"
                                    width="25" height="25" x="0" y="0" viewBox="0 0 24 24"
                                    style="enable-background:new 0 0 512 512; transform: translate(0, -2px);"
                                    xml:space="preserve">
                                    <g>
                                        <clipPath id="a">
                                            <path d="M0 0h24v24H0z" fill="#000000" data-original="#000000"></path>
                                        </clipPath>
                                        <g fill="#000" clip-path="url(#a)">
                                            <path d="M7 5.5a5 5 0 0 1 10 0V11a5 5 0 0 1-10 0z" fill="#6d5930"
                                                data-original="#000000"></path>
                                            <path
                                                d="M12.006 18h-.012a7.003 7.003 0 0 1-6.485-4.375 1 1 0 0 0-1.854.75A9.008 9.008 0 0 0 11 19.945V23a1 1 0 1 0 2 0v-3.055a9.008 9.008 0 0 0 7.346-5.57 1 1 0 0 0-1.854-.75A7.002 7.002 0 0 1 12.006 18z"
                                                fill="#6d5930" data-original="#000000"></path>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from '../axios_config';

export default {
    name: 'MyComponent',
    // Your script code here
    data() {
        return {
            recorder: null,
            chunks: [],
            device: null,
            blobObj: null,
            recording: false,
        }
    },
    created() {
        this.device = navigator.mediaDevices.getUserMedia({ audio: true });
    },
    mounted() {
        // this.loadMistakes()
        // this.$store.state.history
    },
    methods: {
        recordAudio(parrent) {
            console.log("this", parrent)
            this.device.then((stream) => {
                this.recorder = new MediaRecorder(stream);
                this.recorder.ondataavailable = async (e) => {
                    this.chunks.push(e.data);
                    if (this.recorder.state === "inactive") {
                        let blob = new Blob(this.chunks, { type: "audio/wav" });
                        // save to blobObj
                        this.blobObj = blob;
                        this.chunks = [];
                        var audio = parrent.children[1].firstChild.firstChild.firstChild;
                        const childElements = audio.children;
                        if (childElements[0] != undefined) {
                            audio.removeChild(childElements[0])
                        }
                        var mainaudio = document.createElement("audio");
                        mainaudio.setAttribute("controls", "controls");
                        audio.appendChild(mainaudio);
                        mainaudio.innerHTML =
                            '<source src="' +
                            URL.createObjectURL(blob) +
                            '" type="audio/wav" />';
                        this.blobObj = null;


                        console.log(blob)
                        // Create a new FormData object
                        const formData = new FormData();

                        // Append the Blob object to the FormData object with a filename
                        formData.append("file", blob, "record.wav");
                        // Use Axios to send the FormData object to the server
                        var span = parrent.firstChild.firstChild.firstChild
                        console.log(span)
                        formData.append("text", `${span.innerHTML}`);
                        this.loading = true
                        var res = null
                        await axios.post("/transcribe", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data"
                            }
                        })
                            .then(response => {
                                // Handle the server response
                                console.log(response)
                                res = response.data.tokens;
                                // var text = textElement.innerHTML;
                                this.loading = false;
                                // setTimeout(() => {}, 100)
                            })
                            .catch(error => {
                                // Handle errors
                                console.log(error)
                            });
                        if (res[0][1] == false) {
                            span.style.color = 'red';
                        }
                        else {
                            span.style.color = 'green';
                        }
                        console.log(res)
                    }
                };
                // start
                this.recorder.start();
                this.recording = true;
            });
        },
        stop() {
            this.recorder.stop();
            this.recording = false;
        }
    }
}
</script>
  
<style scoped></style>