import { createApp } from 'vue'
import App from './App.vue'
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import router from "./router";
import "@/assets/css/style.css";
// import "@/assets/css/audioStyle.less";
// import "@/assets/css/audioStyle.css";
import '@fortawesome/fontawesome-free/css/all.css'


const app = createApp(App)
app.use(router)
app.use(store);
app.mount('#app')