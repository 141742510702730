<template>
  <div
    class="dropdown"
    style="transform: translate(-171px, 12px); z-index: 100; height: 0"
  >
    <button
      id="soraBtn"
      class="button btn dropdown-toggle"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ currentSurahName }}
    </button>
    <ul class="dropdown-menu">
      <li v-for="(surah, index) in surahs" :key="index">
        <a class="dropdown-item" href="#" @click="changeSurah(index)">
          {{ surah.name }}
        </a>
      </li>
    </ul>
  </div>
  <!--End part-->
  <div class="nav2">
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-6" style="">
            <div id="audioContainer1">
              <audio></audio>
            </div>
            <AudioPlayer2></AudioPlayer2>
          </div>
          <div
            class="col-sm-6"
            style="
              font-size: 16px;
              transform: translate(-164px, 12px);
              margin-top: 10px;
              color: #47230f;
              display: inline-flex;
              justify-content: left;
            "
          >
            استاد عبدالباسط عبدالصمد
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-8">
            <div class="" style="transform: translate(174px, 0px)">
              <input
                dir="rtl"
                type="text"
                id="tinput"
                placeholder="شماره آیه را وارد کنید:"
                required
                maxlength="20"
                size="8"
                autocomplete="off"
                v-model="ayah"
                @keyup.enter="loadText"
              />
              <span class="sp"
                ><span>{{ converDigit(ayah) }}/</span
                ><span v-if="soreh == 2">{{ converDigit(78) }}</span
                ><span v-if="soreh == 1">{{ converDigit(83) }}</span></span
              >
            </div>
          </div>
          <img
            class="col-sm-4"
            src="../assets/Quran.png"
            style="transform: translate(85px, -2px); height: 80px; width: 102px"
          />
        </div>
      </div>
    </div>
  </div>
  <div id="box3">
    <div id="carouselExample" class="carousel slide">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="row cont" style="height: 60vh">
            <LoadingSpinner v-if="loading"></LoadingSpinner>
            <div v-if="!loading" style="">
              <p
                ref="ayah"
                id="ayah"
                style="word-break: break-all; word-wrap: break-word"
                v-bind:style="{ fontSize: fontSize }"
                v-html="html"
              ></p>
              <div id="image-container">
                <span id="text-overlay">{{ converDigit(String(ayah)) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExample"
        data-bs-slide="prev"
        @click="prev()"
        style="height: 80%"
      >
        <span
          class="carousel-control-prev-icon"
          aria-hidden="true"
          style="margin-top: 50%"
        ></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExample"
        data-bs-slide="next"
        @click="next()"
        style="height: 80%"
      >
        <span
          class="carousel-control-next-icon"
          aria-hidden="true"
          style="margin-top: 50%"
        ></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    <hr size="6" color="white" width="100%" style="opacity: 0.5" />
    <div class="row myFooter">
      <div class="col-md-6">
        <AudioPlayer></AudioPlayer>
        <div id="audioContainer2">
          <audio id="recordedAudio"></audio>
        </div>
      </div>
      <div class="col-md-6">
        <button
          @click="!recording ? recordAudio() : stop()"
          type="button"
          id="button_record"
          class="recordBtn"
          v-bind:style="{ opacity: recording ? 0.5 : 1 }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:svgjs="http://svgjs.com/svgjs"
            width="40"
            height="40"
            x="0"
            y="0"
            viewBox="0 0 24 24"
            style="
              enable-background: new 0 0 512 512;
              transform: translate(0, -2px);
            "
            xml:space="preserve"
          >
            <g>
              <clipPath id="a">
                <path
                  d="M0 0h24v24H0z"
                  fill="#000000"
                  data-original="#000000"
                ></path>
              </clipPath>
              <g fill="#000" clip-path="url(#a)">
                <path
                  d="M7 5.5a5 5 0 0 1 10 0V11a5 5 0 0 1-10 0z"
                  fill="#6d5930"
                  data-original="#000000"
                ></path>
                <path
                  d="M12.006 18h-.012a7.003 7.003 0 0 1-6.485-4.375 1 1 0 0 0-1.854.75A9.008 9.008 0 0 0 11 19.945V23a1 1 0 1 0 2 0v-3.055a9.008 9.008 0 0 0 7.346-5.57 1 1 0 0 0-1.854-.75A7.002 7.002 0 0 1 12.006 18z"
                  fill="#6d5930"
                  data-original="#000000"
                ></path>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>
    <div class="userText" v-bind:style="{ fontSize: fontSize }">
      {{ userText }}
    </div>
  </div>
</template>
<script>
import axios from "../axios_config";
import LoadingSpinner from "../components/LoadingSpinner.vue";
import AudioPlayer from "../components/AudioPlayer.vue";
import AudioPlayer2 from "../components/AudioPlayer2.vue";

export default {
  name: "MainContent",
  components: {
    LoadingSpinner,
    AudioPlayer,
    AudioPlayer2,
  },
  data() {
    return {
      fontSize: "112px",
      recorder: null,
      chunks: [],
      device: null,
      blobObj: null,
      recording: false,
      surahs: [],
      currentSurahIndex: 0,
      ayah: 0,
      loading: false,
      text: "",
      html: "",
      userText: "",
    };
  },
  computed: {
    currentSurahName() {
      return this.surahs.length > 0
        ? this.surahs[this.currentSurahIndex].name
        : "";
    },
  },
  created() {
    this.device = navigator.mediaDevices.getUserMedia({ audio: true });
    this.fetchSurahs();
  },
  methods: {
    fetchSurahs() {
      axios.get("/all-surah-name").then((response) => {
        this.surahs = response.data.map((surah) => ({
          id: Object.keys(surah)[0],
          name: surah[Object.keys(surah)[0]],
          firstAyah: surah.first,
        }));
        if (this.surahs.length > 0) {
          this.currentSurahIndex = 0;
          this.ayah = this.surahs[0].firstAyah;
          this.loadText();
        }
      });
    },
    changeSurah(index) {
      this.currentSurahIndex = index;
      this.ayah = this.surahs[index].firstAyah;
      this.loadText();
    },
    loadText() {
      this.loadAudio();
      const formData = new FormData();
      const surahId = this.formatSurahId(
        this.surahs[this.currentSurahIndex].id
      );

      axios
        .get(`/surah:${surahId}/ayah:${this.ayah}/text`, formData, {})
        .then((response) => {
          var text = response.data.text;
          this.text = text;
          this.html = text;
          this.changeFont();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadAudio() {
      var audiocon = document.querySelector("#audioContainer1");
      const surahId = this.formatSurahId(
        this.surahs[this.currentSurahIndex].id
      );
      axios
        .get(`/surah:${surahId}/ayah:${this.ayah}/audio/`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          var audio = response.data;
          console.log(audio);
          let blob = new Blob([audio], { type: "audio/mp3" });
          const childElements = audiocon.children;
          if (childElements.length > 0) {
            audiocon.removeChild(childElements[0]);
          }
          var mainaudio = document.createElement("audio");
          mainaudio.id = "recievedAudio";
          audiocon.appendChild(mainaudio);
          mainaudio.innerHTML =
            '<source src="' +
            URL.createObjectURL(blob) +
            '" type="audio/mp3" />';
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatSurahId(id) {
      const paddedId = id.padStart(3, "0");
      return paddedId;
    },
    changeFont() {
      var text = this.html;
      var len = text.length;
      var newSize = Math.pow(10, -(len / 300 - 1.7)) + 32;
      this.fontSize = newSize + "px";
    },
    next() {
      this.ayah--;
      this.loadText();
    },
    prev() {
      this.ayah++;
      this.loadText();
    },
    converDigit(d) {
      d = String(d);
      const e2a = (s) => s.replace(/\d/g, (d) => "۰۱۲۳۴۵۶۷۸۹"[d]);
      return e2a(d);
    },
    recordAudio() {
      this.device.then((stream) => {
        this.recorder = new MediaRecorder(stream);
        this.recorder.ondataavailable = async (e) => {
          this.chunks.push(e.data);
          if (this.recorder.state === "inactive") {
            let blob = new Blob(this.chunks, { type: "audio/wav" });
            this.blobObj = blob;
            this.chunks = [];
            var audio = document.getElementById("audioContainer2");
            const childElements = audio.children;
            if (childElements[0] != undefined) {
              audio.removeChild(childElements[0]);
            }
            var mainaudio = document.createElement("audio");
            mainaudio.id = "recordedAudio";
            audio.appendChild(mainaudio);
            mainaudio.innerHTML =
              '<source src="' +
              URL.createObjectURL(blob) +
              '" type="audio/wav" />';
            this.blobObj = null;

            const formData = new FormData();
            formData.append("file", blob, "record.wav");
            formData.append(
              "address",
              `${this.surahs[this.currentSurahIndex].id}/${this.ayah}`
            );
            this.loading = true;
            var res = [];
            await axios
              .post("/transcribe/", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
                var array = response.data.tokens;
                var trans_text = response.data["transcribed text"];
                this.userText = trans_text;
                for (let index = 0; index < array.length; index++) {
                  var span = this.createSpan(
                    array[index][1],
                    array[index][2],
                    array[index][3]
                  );
                  res.push(span);
                }
                this.loading = false;
              })
              .catch((error) => {
                console.log(error);
              });
            var ptag = document.querySelector("#ayah");
            ptag.style.background = "rgb(0 0 0 / 51%)";
            ptag.style.borderRadius = "8px";
            this.html = res.join(" ");
          }
        };
        this.recorder.start();
        this.recording = true;
      });
    },
    stop() {
      this.recorder.stop();
      this.recording = false;
    },
    createSpan(start, end, correct) {
      var text = this.text;
      var newText = "";
      if (correct) {
        newText =
          "<span style='color: green;'>" +
          text.substring(start, end) +
          "</span>";
      } else {
        newText =
          "<span style='color: red;'>" + text.substring(start, end) + "</span>";
      }
      return newText;
    },
  },
};
</script>

<style scoped></style>
