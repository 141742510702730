import { createRouter, createWebHistory } from 'vue-router'
import MainContent from '../view/MainContent.vue';
import History from '../view/History.vue';

const routes = [
    {
        path: '/',
        name: 'main',
        component: MainContent,
    },
    {
        path: '/history',
        name: 'History',
        component: History,
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
      }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: 'active'
})
export default router